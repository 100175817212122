<template>
  <div class='border-t bg-white flex flex-col py-8'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>가톨릭의대 내과 연수강좌 준비사무국</div>
        <div class='mt-2'>(07806) 서울시 강서구 공항대로 220 우성에스비타워Ⅱ 1101호</div>
        <div class='mt-2'><span class='uppercase inline-block w-36 opacity-70'>Contact Number</span>02-6734-1012/1013</div>
        <div class='mt-2'><span class='uppercase inline-block opacity-70 w-36 '>Fax</span>02-6734-1009</div>
        <div class='mt-2'><span class='uppercase inline-block w-36 opacity-70'>Email</span>cmcimt@planbear.co.kr</div>
      </div>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>가톨릭대학교 의과대학 내과학교실</div>
        <div class='mt-2'>(06591) 서울시 서초구 반포대로 222 서울성모병원</div>
        <div class='mt-2'><span class='uppercase inline-block opacity-70 w-36 '>Contact Number</span>02-2258-2901~02</div>
        <div class='mt-2'><span class='uppercase inline-block opacity-70 w-36 '>Web Site</span>https://www.cmcmed.co.kr</div>
        <div class='mt-2'>Copyright © by 2023년 제16회 가톨릭대학교 의과대학 내과 연수강좌. All rights reserved.</div>
      </div>
      <div class='flex flex-col lg:justify-start lg:items-start justify-center items-center'>
        <img :src='eventConfigLogoImageUrl'
             class='h-12 cursor-pointer mb-4 w-auto'
             @click='goToHome'/>
        <button
          v-if='eventKakaoSupportUrl'
          class='px-8 py-2 text-yellow-900 font-semibold rounded-md mb-4'
          style='background-color: #f6e113; color: #4b383f;'
          @click='kakaoChannelChat'>
          <chat-alt-2-icon class='inline-block mr-2' />카카오톡 문의하기
        </button>
        <button
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          로그아웃
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ChatAlt2Icon } from '@vue-hero-icons/outline'

export default {
  name: 'Cmccme2023LayoutFooter',
  components: {
    ChatAlt2Icon
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'eventKakaoSupportUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
    kakaoChannelChat () {
      window.open(this.eventKakaoSupportUrl, '_blank', 'width=350,height=550');
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
